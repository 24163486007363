.entity-table-actions {
  margin: 20px;

  .entity-table-actions-inner {
    display: flex;
    flex-wrap: wrap;
    border-radius: 3px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: var(--rootkit__palette--gray100);
  }
}

.entity-table-actions-select {
  display: flex;
  align-items: center;
  font-family: $rootkit__font--heading;
  font-size: 16px;

  .select-all {
    display: inline-block;

    .input-checkbox {
      position: relative;
    }
  }

  .selected {
    display: inline-block;
  }

  .selected-separator {
    display: inline-block;
    color: $rootkit__color-grey--light;
    margin: 0 0.5rem;
  }

  .select-total {
    display: inline-block;
    color: $rootkit__color-grey--light;
    text-decoration: underline;
    cursor: pointer;
  }
}

.entity-table-actions-dispatch {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5em;

  .dropdown-content {
    right: 0;
  }

  .render-effect-popper {
    margin-right: 0.5em;
  }

  // .dropdown-new {
  //   margin-right: 0.5em;
  // }

  // > * {
  //   margin-top: 8px;
  // }
}

.event-log__export-all {
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem 1.25rem;
}

.entity-table-action {
  margin-right: 0.5em;

  &:last-child {
    margin-right: 0;
  }
}

.entity-actions-more-list {
  @include rootkit__list-reset;

  li:hover {
    background-color: var(--rootkit__palette--gray200);
  }
}

.entity-actions-list-container {
  @include rootkit__list-reset;

  margin: 1rem;
}

.entity-actions-more-list-item {
  padding: 0.5rem;
  margin: 1rem;

  &:hover {
    cursor: pointer;
    background: var(--rootkit__palette--gray200);
  }
}

.entity-actions-list-item {
  margin-top: 0.25rem;
  padding: 0.5rem;
  margin-bottom: 0.25rem;

  &:hover {
    cursor: pointer;
    background: var(--rootkit__palette--gray200);
  }
}

.mark-status-list {
  min-width: 200px;
}

.to-target-link {
  @include r-link($underline: false);

  &:hover {
    text-decoration: underline;
  }

  &::after {
    display: inline-block;
    position: relative;
    content: '';
    width: 1rem;
    height: 1rem;
    margin: 0 0.5rem;
    background-image: url('../static/images/svg-out/external-link.svg');
    background-repeat: no-repeat;
    background-size: 1rem;
    opacity: 0.5;
  }
}

.detections-modal {
  .detections-modal-leader {
    border-bottom: 1px solid $rootkit__color-grey--lighter;
  }

  .detections-modal-description {
  }

  .detections-modal-records {
    margin: 0 -2rem 2rem;
    padding: 0.5rem 2rem;
    background: $rootkit__color-grey--lighter;
  }

  .detections-modal-actions {
    text-align: right;

    > button {
      margin-left: 1rem;
    }
  }

  .detections-modal-header {
    > .accordion-card-cell {
      font-size: 12px;
    }
  }

  .detections-modal-row {
    @extend .target;
  }

  .next-state {
    font-weight: 700;
  }
}

.entity-action-toggle {
  background-color: var(--rootkit__palette--gray100);
}

.entity-action-dropdown-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  > div:first-child {
    margin-right: 15px;
  }

  > div:last-child {
    white-space: nowrap;
  }
}

.entity-action-dropdown-footer {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.entity-action-dropdown-container {
  margin: 15px;
}

.entity-action-dropdown-comment {
  min-width: 270px;
}

.entity-action-dropdown-actions {
  margin-top: 3px;
  display: flex;
  justify-content: flex-end;

  > div:first-child {
    margin-right: 0.5rem;
  }
}

.entity-action-button-inline {
  width: 170px;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  white-space: nowrap;

  div:first-child {
    width: 25px;
  }
}

.entity-action-button-inline-selected {
  background-color: var(--rootkit__palette--gray200);
}
