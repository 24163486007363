$event-log-pane-padding: 50px;

.creation-date-range {
  display: inline-flex;
}

.creation-date-range__separator {
  display: inline-flex;
  margin: 8px;
  align-items: last baseline;
}

.creation-date__dropdown {
  width: 375px;
}

.creation-date__dropdown-item {
  width: fit-content;
}

.configure-event-log-view-pane {
  width: 600px;
  overflow-y: hidden;

  .slideout-header {
    padding: 30px $event-log-pane-padding 0;
  }

  .slideout-body {
    display: flex;
    flex-direction: column;
    padding: 0 0 15px !important;
    overflow-y: hidden;

    form {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .configure-event-log-view__title-row {
      padding: 0 15px 15px $event-log-pane-padding;
      border-bottom: 1px solid $rootkit__color-blue--lighter;
      display: flex;
      align-items: center;

      .title-text {
        margin: 0;
      }
    }

    .pane-body {
      flex: 1;
      overflow-y: auto;

      .icon-status--none svg {
        stroke: none;
        fill: $rootkit__color-red;
        opacity: 1;
      }

      .label-row {
        display: flex;
        align-items: center;
        height: 30px;

        .icon {
          margin-left: -30px;
          margin-right: 15px;
        }
      }

      .subheader,
      .event-log__event-type,
      .event-log__creation-time {
        display: flex;
        flex-direction: column;
        padding: 15px $event-log-pane-padding;

        .dropdown-new {
          margin: 4px 0;

          .dropdown-new-content {
            width: 100%;

            .dropdown-new-options {
              padding: 0 0 15px;

              .dropdown-new-option {
                padding: 0.5em 15px;

                .input-radio {
                  margin: 0;
                }
              }
            }

            .list-divider {
              padding-top: 15px;
              position: relative;
              z-index: 1;

              &::before {
                border-top: 1px solid $rootkit__color-blue--lighter;
                content: '';
                margin: 0 auto;
                position: absolute;
                top: 70%;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                z-index: -1;
              }

              .line-text {
                color: $rootkit__color-grey;
                background: var(--rootkit__palette--white);
                padding: 0;
                margin-left: 15px;
              }
            }

            .option-title {
              padding: 20px 15px 10px;
              line-height: 1.5em;
              font-size: 12px;
              color: $rootkit__color-grey;
              text-transform: uppercase;
            }
          }

          .button {
            width: 100%;

            .button-flex {
              justify-content: space-between;
            }
          }
        }
      }
    }

    .event-log__footer {
      padding-top: 15px;
      border-top: 1px solid $rootkit__color-blue--lighter;

      .button-group {
        justify-content: flex-end;
      }
    }
  }
}

.subheader__text {
  font-weight: 600;
  font-size: 20px;
}
